import { useEffect } from "react";

export function useEscapeHandler(callback) {
  useEffect(() => {
    function handleEscapeKeyPress(event) {
      if (event.key === "Escape") {
        callback();
      }
    }

    document.addEventListener("keydown", handleEscapeKeyPress);
    return () => {
      document.removeEventListener("keydown", handleEscapeKeyPress);
    };
  }, [callback]);
}
