import { useContext, useState } from "react";
import { MerchantContext } from "src/ecommerce/components/context/MerchantContext";

import { styled } from "stitches.config";
import { Logo } from "./header/Logo";
import { ProgressBarRent } from "src/ecommerce/components/partials/ProgressBarRent";
import MerchantImageXKom from "../../../assets/svg/product/x-kom.svg";
import MerchantImageRomet from "../../../assets/svg/product/romet.svg";
import ChecklistImage from "../../../assets/svg/checklist.svg";
import Popup from "src/ecommerce/components/partials/Popup";

const HeaderStyled = styled("header", {
  padding: "1rem 0",
  display: "flex",
  alignItems: "center",
});

const PartnerStyled = styled("span", {
  display: "flex",
  marginRight: "7px",
  fontSize: "10px",
  fontWeight: "semibold",
  "&::before": {
    content: " ",
    display: "inline-block",
    margin: "0 16px",
    width: "1px",
    height: "16px",
    background: "#adb9c1",
  },
});

const PopupButtonsWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: "16px",
  marginTop: "40px",
  "@md": {
    flexDirection: "row",
  },
});

export function Header({ activeStep, ...props }) {
  const { merchantName } = useContext(MerchantContext);
  const [popupIsVisible, setPopupIsVisible] = useState(false);

  const handleShowPopup = (event, activeStep) => {
    if (activeStep !== 1) {
      event.preventDefault();
      setPopupIsVisible(!popupIsVisible);
    }
  };

  return (
    <HeaderStyled className="mb-6 pt-5" {...props}>
      <div className="container px-lg-0">
        {merchantName == "X-Kom" ? (
          <div className="row d-flex">
            <div className="col-lg-7 col-24 mt-1 mb-lg-2 mb-5 text-center text-lg-start ps-lg-0">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <Logo onClick={(event) => handleShowPopup(event, activeStep)} />
                <PartnerStyled className="">PARTNER</PartnerStyled>
                <MerchantImageXKom width="60px" height="100%" />
              </div>
            </div>

            <div className="col-lg-17 col-24 d-flex align-items-center pe-lg-0 ps-lg-8">
              <ProgressBarRent className="w-100 ps-lg-5" activeStep={activeStep} />
            </div>
          </div>
        ) : merchantName == "Romet" ? (
          <div className="row d-flex">
            <div className="col-lg-7 col-24 mt-1 mb-lg-2 mb-5 text-center text-lg-start ps-lg-0">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <Logo onClick={(event) => handleShowPopup(event, activeStep)} />
                <PartnerStyled className="">PARTNER</PartnerStyled>
                <MerchantImageRomet width="85px" height="100%" />
              </div>
            </div>

            <div className="col-lg-17 col-24 d-flex align-items-center pe-lg-0 ps-lg-8">
              <ProgressBarRent className="w-100 ps-lg-5" activeStep={activeStep} />
            </div>
          </div>
        ) : (
          <div className="row d-flex ">
            <div className="col-lg-4 col-24 mt-1 mb-lg-2 mb-4 text-center text-lg-start ps-lg-0">
              <Logo onClick={(event) => handleShowPopup(event, activeStep)} />
            </div>

            <div className="col-lg-2 col-auto"></div>

            <div className="col-lg-18 col-24 d-flex align-items-center pe-lg-0 ps-lg-9">
              <ProgressBarRent className="w-100 ps-lg-6" activeStep={activeStep} />
            </div>
          </div>
        )}
      </div>

      <Popup
        popupIsVisible={popupIsVisible}
        setPopupIsVisible={setPopupIsVisible}
        title={"Na pewno chcesz przerwać?"}
        Icon={ChecklistImage}
        wide={true}
        tall={true}
      >
        <PopupButtonsWrapper>
          <a href="https://gleevery.com/" className="btn btn-primary px-7">
            Tak, chcę przejść do strony głównej
          </a>
          <button className="btn btn-outline-gray fw-semibold px-7" onClick={() => setPopupIsVisible(!popupIsVisible)}>
            Nie, wróć do wniosku
          </button>
        </PopupButtonsWrapper>
      </Popup>
    </HeaderStyled>
  );
}
