import { HttpError } from "src/utils/errors/HttpError";
import { BadRequestError } from "src/utils/errors/BadReqeustError";
import { InternalServerError } from "src/utils/errors/InternalServerError";

const METHOD_POST = "POST";

const CONTENT_TYPE_HEADER = "content-type";
const CONTENT_TYPE_JSON = "application/json";

export const create = async (data) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + "/api/rest/public/orders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const update = async (code, data) => {
  const updateUrlPath = `/api/rest/public/orders/${code}`;
  const updateUrl = process.env.NEXT_PUBLIC_WEBISTE_URL + updateUrlPath;

  const response = await fetch(updateUrl, {
    method: METHOD_POST,
    headers: {
      [CONTENT_TYPE_HEADER]: CONTENT_TYPE_JSON,
    },
    body: JSON.stringify(data),
  });

  const responseData = await parseResponse(response);

  if (!response.ok) {
    switch (response.status) {
      case 400:
        throw new BadRequestError("Bad Request", responseData);
      case 500:
        throw new InternalServerError("Internal Server Error", responseData);
      default:
        throw new HttpError(response.status, "Unhandled http error", responseData);
    }
  }

  return responseData;
};

export const findOneByCode = async (code) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}`);

  return response.json();
};

export const updateOrderLine = async (code, id, data) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}/lines/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  return response.json();
};

export const findShippingMethodsByCode = async (code) => {
  const response = await fetch(
    process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/orders/${code}/shipping-methods`,
  );

  return response.json();
};

export const clearVerification = async (code) => {
  const response = await fetch(
    process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/order/${code}/clear-verification`,
    {
      method: "POST",
    },
  );

  return response;
};

export const findPayment = async (code) => {
  const response = await fetch(process.env.NEXT_PUBLIC_WEBISTE_URL + `/api/rest/public/payments/${code}`);

  return response.json();
};

const parseResponse = async (response) => {
  if (responseIsJSON(response)) {
    return await response.json();
  }

  return await response.text();
};

const responseIsJSON = (response) => {
  return response.headers.get(CONTENT_TYPE_HEADER)?.includes(CONTENT_TYPE_JSON);
};
